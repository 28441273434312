import React from "react";
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby";

import "../../../css/components/sliders/PartnersSlider.scss";

const PartnersSlider = () => {
  const queriedData = useStaticQuery(graphql`
    query PartnersSliderQuery {
      strapiPartner: strapiPartner {
        slides {
          localFile {
            url
          }
          id
        }
      }
    }
  `);

  const allPartnerSlides = queriedData.strapiPartner.slides.reverse();


  // Const slider settings
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    className: "center partners-slider",
    centerPadding: "60px",
    centerMode: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };
  return (
    <section className="partners-container">
      <Slider {...sliderSettings}>
        {allPartnerSlides.map((item, i) => {
          return (
            <div key={"partners" + i}>
              <img src={item.localFile.url} alt={item.alternativeText} />
            </div>
          );
        })}
      </Slider>
    </section>
  );
};

export default PartnersSlider;
