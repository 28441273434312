import React, { useState } from "react";
import { Link } from "gatsby";
import Slider from "react-slick";

import "../../../css/components/sliders/ImageSlider.scss";

const ImageSlider = (props) => {
  const [isHoveredOne, setIsHoveredOne] = useState(false);
  const [isHoveredTwo, setIsHoveredTwo] = useState(false);
  const [isHoveredThree, setIsHoveredThree] = useState(false);
  const [isHoveredFour, setIsHoveredFour] = useState(false);
  const onHoverHandle = (itemNumber) => {
    switch (itemNumber) {
      case 1:
        setIsHoveredOne(!isHoveredOne);
        break;
      case 2:
        setIsHoveredTwo(!isHoveredTwo);
        break;
      case 3:
        setIsHoveredThree(!isHoveredThree);
        break;
      case 4:
        setIsHoveredFour(!isHoveredFour);
        break;
      default:
        break;
    }
  };

  const {
    image1,
    image2,
    image3,
    image4,
    logo1,
    logo2,
    logo3,
    logo4,
    subtitle1,
    subtitle2,
    subtitle3,
    subtitle4,
    link1,
    link2,
    link3,
    link4
  } = props;

  // Const slider settings
  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    className: "center image-slider",
    centerPadding: "60px",
    centerMode: false,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  /* eslint-disable */
  return (
    <section className="image-slider-container">
      <Slider {...sliderSettings}>
        <Link to={link1}>
          <div className="overlay-text first">
            <h2>{subtitle1}</h2>
          </div>
          <img
            src={image1}
            alt={subtitle1}
            onMouseOver={() => {
              onHoverHandle(1);
            }}
            onMouseOut={() => {
              onHoverHandle(1);
            }}
            onFocus={() => {
              onHoverHandle(1);
            }}
            onBlur={() => {
              onHoverHandle(1);
            }}
            className={isHoveredOne ? "animate_hover" : ""}
          />
          {logo1 && (
            <img
              src={logo1}
              className="logo logo-1"
              alt=""
              onMouseOver={() => {
                onHoverHandle(1);
              }}
              onMouseOut={() => {
                onHoverHandle(1);
              }}
              onFocus={() => {
                onHoverHandle(1);
              }}
              onBlur={() => {
                onHoverHandle(1);
              }}
            />
          )}
        </Link>
        <Link to={link2}>
          <div className="overlay-text">
            <h2>{subtitle2}</h2>
          </div>
          <img
            src={image2}
            alt={subtitle2}
            onMouseOver={() => {
              onHoverHandle(2);
            }}
            onMouseOut={() => {
              onHoverHandle(2);
            }}
            onFocus={() => {
              onHoverHandle(2);
            }}
            onBlur={() => {
              onHoverHandle(2);
            }}
            className={isHoveredTwo ? "animate_hover" : ""}
          />
          {logo2 && (
            <img
              src={logo2}
              className="logo logo-2"
              alt=""
              onMouseOver={() => {
                onHoverHandle(2);
              }}
              onMouseOut={() => {
                onHoverHandle(2);
              }}
              onFocus={() => {
                onHoverHandle(2);
              }}
              onBlur={() => {
                onHoverHandle(2);
              }}
            />
          )}
        </Link>
        <Link to={link3}>
          <div className="overlay-text">
            <h2>{subtitle3}</h2>
          </div>
          <img
            src={image3}
            alt={subtitle3}
            onMouseOver={() => {
              onHoverHandle(3);
            }}
            onMouseOut={() => {
              onHoverHandle(3);
            }}
            onFocus={() => {
              onHoverHandle(3);
            }}
            onBlur={() => {
              onHoverHandle(3);
            }}
            className={isHoveredThree ? "animate_hover" : ""}
          />

          {logo3 && (
            <img
              src={logo3}
              className="logo logo-3"
              alt=""
              onMouseOver={() => {
                onHoverHandle(3);
              }}
              onMouseOut={() => {
                onHoverHandle(3);
              }}
              onFocus={() => {
                onHoverHandle(3);
              }}
              onBlur={() => {
                onHoverHandle(3);
              }}
            />
          )}
        </Link>
        <Link to={link4}>
          <div className="overlay-text fourth">
            <h2>{subtitle4}</h2>
          </div>
          <img
            src={image4}
            alt={subtitle4}
            onMouseOver={() => {
              onHoverHandle(4);
            }}
            onMouseOut={() => {
              onHoverHandle(4);
            }}
            onFocus={() => {
              onHoverHandle(4);
            }}
            onBlur={() => {
              onHoverHandle(4);
            }}
            className={isHoveredFour ? "animate_hover" : ""}
          />
          {logo4 && (
            <img
              src={logo4}
              onMouseOver={() => {
                onHoverHandle(4);
              }}
              onMouseOut={() => {
                onHoverHandle(4);
              }}
              onFocus={() => {
                onHoverHandle(4);
              }}
              onBlur={() => {
                onHoverHandle(4);
              }}
              className="logo logo-4"
              alt=""
            />
          )}
        </Link>
      </Slider>
    </section>
  );
};

export default ImageSlider;
