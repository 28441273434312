import React from "react";

import "../../../css/components/blocks/SimpleBlock.scss";

const SimpleBlock = ({
  headingText,
  backgroundColor,
  textColor,
  blockSize,
  maxWidthText
}) => {
  const getBlockClass = () => {
    if (blockSize === 1) {
      return "block half-block";
    } else if (blockSize === 2) {
      return "block full-block";
    }
  };

  return (
    <div className={getBlockClass()} style={{ backgroundColor: backgroundColor, color: textColor }}>
      <h3 lang="hu" style={{ maxWidth: maxWidthText }}>
        {headingText}
      </h3>
    </div>
  );
};

export default SimpleBlock;
