import React from "react";

import { Container, Row, Col, Image } from "react-bootstrap";

import "../../css/components/SectionContainer.scss";

const SectionContainer = (props) => {
  const {
    background,
    headingText,
    paragraph,
    span,
    spanIsBold,
    backgroundImage,
    customClass,
  } = props;
  const containerClass = () => {
    let containerClass = customClass ? customClass + " " : "";
    if (background === "white") {
      return (containerClass += "white-bg-container");
    } else if (background === "black") {
      return (containerClass += "black-bg-container");
    } else if (background === "image") {
      return (containerClass += "image-bg-container");
    }
  };

  const addSpan = () => {
    if (span) {
      return (
        <div>
          {paragraph} <br></br>
          {span && spanIsBold ? (
            <span>
              <b style={{ fontWeight: 700 }}>{span}</b>
            </span>
          ) : (
            <span>{span}</span>
          )}
        </div>
      );
    } else {
      return <div>{paragraph}</div>;
    }
  };

  return (
    <Container fluid className={containerClass()}>
      <Row>
        <Col md={12} lg={6} className="left-block pl-0">
          <h2>{headingText}</h2>
          {addSpan()}
        </Col>
        <Col md={12} lg={6} className="right-block p-0">
          {props.children}
        </Col>
      </Row>
      {backgroundImage && (
        <div className="img-overlay">
          <Image fluid src={backgroundImage} alt="Üzletfejlesztés háttér" className="bg-image"></Image>
        </div>
      )}
    </Container>
  );
};

export default SectionContainer;
