import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../css/components/TitleBar.scss";

const TitleBar = ({ headingText }) => {
  return (
    <section>
      <Container fluid className="title-bar">
        <Row>
          <Col md={12} lg={6} className="title-left">
            <h2>{headingText}</h2>
          </Col>
          <Col md={12} lg={6} className="decor-right"></Col>
        </Row>
      </Container>
    </section>
  );
};

export default TitleBar;
