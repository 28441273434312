import React, { useEffect } from "react";
import { Container, Row, Col, Ratio } from "react-bootstrap";
import { useStaticQuery, graphql, Link } from "gatsby";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { getFilteredBoxes } from "../utils";

import Seo from "../components/seo";
import Header from "../components/Navbar/Header/Header";
import TitleBar from "../components/TitleBar/TitleBar";
import ImageSlider from "../components/Sliders/ImageSlider/ImageSlider";
import SectionContainer from "../components/SectionContainer/SectionContainer";
import SimpleBlock from "../components/Blocks/SimpleBlock/SimpleBlock";
import PartnersSlider from "../components/Sliders/PartnersSlider/PartnersSlider";
import Footer from "../components/Footer/Footer";
import BlocksContainer from "../components/BlocksContainer/BlocksContainer";
import Contact from "../components/Contact/Contact";
import ImageBlock from "../components/Blocks/ImageBlock/ImageBlock";

import "../css/pages/index.scss";
import TamogatasLogo from "../images/tamogatas.jpeg";

// Kezdőlap
const IndexPage = () => {
  useEffect(() => {
    document.getElementById("intro-video-1").play();
  });

  const queriedData = useStaticQuery(graphql`
    query IndexQuery {
      site: site {
        siteMetadata {
          siteUrl
        }
      }
      strapiEmbeddedVideo: strapiEmbeddedVideo {
        video_source {
          localFile {
            url
          }
        }
      }
      allStrapiBoxes: allStrapiBox {
        nodes {
          title
          link
          id
          background {
            alternativeText
            localFile {
              url
            }
          }
          logo {
            alternativeText
            localFile {
              url
            }
          }
          box_group {
            title
            id
            strapi_id
          }
        }
      }
      allStrapiBoxGroups: allStrapiBoxGroup {
        nodes {
          title
          strapi_id
          description {
            data {
              description
            }
          }
          background {
            localFile {
              url
            }
            alternativeText
          }
        }
      }
    }
  `);

  const siteUrl = queriedData.site.siteMetadata.siteUrl;

  const strapiEmbeddedVideoSource = queriedData.strapiEmbeddedVideo.video_source.localFile.url;

  const allStrapiBoxGroups = queriedData.allStrapiBoxGroups.nodes;

  // extract slides for references
  const allStrapiBoxes = queriedData.allStrapiBoxes.nodes;

  const referenceSlides = getFilteredBoxes(allStrapiBoxes, "highlighted-references");
  const citySolutionBoxes = getFilteredBoxes(allStrapiBoxes, "city-solutions");

  const productDevelopmentBoxes = getFilteredBoxes(allStrapiBoxes, "product-development");
  const businessDevelopmentBoxes = getFilteredBoxes(allStrapiBoxes, "business-development");
  const referenceBoxes = getFilteredBoxes(allStrapiBoxes, "references");

  return (
    <>
      <Seo title={"Kezdőlap"}></Seo>
      <Header />
      <main className="index-page">
        <a href="https://udg.hu/page/kutatas-fejlesztes" className="tamogatas">
             <img src={siteUrl + TamogatasLogo} alt="Tamogatas" />
        </a>
        <Ratio aspectRatio="16x9">
          <video id="intro-video-1" title={"Urben design bemutatóvideó"} width="100%" height="100%" autoPlay muted loop>
            <source src={strapiEmbeddedVideoSource} type="video/mp4" />
          </video>
        </Ratio>
        <TitleBar headingText={allStrapiBoxGroups[0].title}></TitleBar>
        <ImageSlider
          logo1={referenceSlides[0].logo}
          logo2={referenceSlides[1].logo}
          logo3={referenceSlides[2].logo}
          logo4={referenceSlides[3].logo}
          image1={referenceSlides[0].background}
          image2={referenceSlides[1].background}
          image3={referenceSlides[2].background}
          image4={referenceSlides[3].background}
          subtitle1={referenceSlides[0].title}
          subtitle2={referenceSlides[1].title}
          subtitle3={referenceSlides[2].title}
          subtitle4={referenceSlides[3].title}
          link1={referenceSlides[0].link}
          link2={referenceSlides[1].link}
          link3={referenceSlides[2].link}
          link4={referenceSlides[3].link}></ImageSlider>

        <div id="city-solutions"></div>
        <SectionContainer
          background={"black"}
          key={allStrapiBoxGroups[1].id}
          headingText={allStrapiBoxGroups[1].title}
          paragraph={<ReactMarkdown children={allStrapiBoxGroups[1].description.data.description} />}>
          <>
            <BlocksContainer>
              {citySolutionBoxes &&
                citySolutionBoxes.map((item, index) => (
                  <ImageBlock
                    key={item.id}
                    headingText={item.title}
                    backgroundImage={item.background}
                    textColor={"white"}
                    hasBackArrow={false}
                    linkTo={`${siteUrl}/project${item.link}`}></ImageBlock>
                ))}
            </BlocksContainer>
          </>
        </SectionContainer>

        <div id="product-development"></div>
        <SectionContainer
          background={"white"}
          key={allStrapiBoxGroups[2].id}
          headingText={allStrapiBoxGroups[2].title}
          paragraph={<ReactMarkdown children={allStrapiBoxGroups[2].description.data.description} />}
          customClass={"no-bottom-margins custom-right-block"}>
          <>
            <ImageBlock
              headingText={productDevelopmentBoxes[0].title}
              backgroundImage={productDevelopmentBoxes[0].background}
              textColor={"white"}
              linkTo={`${siteUrl}/project${productDevelopmentBoxes[0].link}`}></ImageBlock>
          </>
        </SectionContainer>
        <Container fluid className="bg-white product-dev-container" style={{ paddingBottom: "180px" }}>
          <Row>
            {productDevelopmentBoxes &&
              productDevelopmentBoxes.map((item, index) => {
                return index > 0 && index < 5 ? (
                  <Col key={item.id} sm={12} md={6} lg={6} xl={6} className="p-0">
                    <ImageBlock headingText={item.title} backgroundImage={item.background} textColor={"white"} linkTo={`${siteUrl}/project${item.link}`}></ImageBlock>
                  </Col>
                ) : (
                  <></>
                );
              })}
          </Row>
          <Row>
            <Col sm={12} md={{ span: 6, offset: 6 }} lg={{ span: 6, offset: 6 }} xl={{ span: 6, offset: 6 }} className="p-0">
              {" "}
              <ImageBlock
                key={productDevelopmentBoxes[5].id}
                headingText={productDevelopmentBoxes[5].title}
                backgroundImage={productDevelopmentBoxes[5].background}
                textColor={"white"}
                linkTo={`${siteUrl}/project${productDevelopmentBoxes[5].link}`}></ImageBlock>
            </Col>
          </Row>
        </Container>

        <div id="business-development"></div>
        <SectionContainer
          background={"image"}
          key={allStrapiBoxGroups[3].id}
          headingText={allStrapiBoxGroups[3].title}
          paragraph={<ReactMarkdown children={allStrapiBoxGroups[3].description.data.description} />}
          backgroundImage={allStrapiBoxGroups[3].background.localFile.url}
          customClass={"custom-margin-top"}>
          <>
            <Container
              fluid
              style={{
                paddingRight: "15px",
              }}>
              <Row>
                {businessDevelopmentBoxes &&
                  businessDevelopmentBoxes.map((item, index) => {
                    return index < 2 ? (
                      <Col key={item.id} sm={12} md={6} lg={6} className="p-0">
                        <Link to={`/${item.link}`} className="simple-block-link">
                          <SimpleBlock maxWidthText={"50%"} headingText={item.title} backgroundColor={"black"} textColor={"white"} blockSize={1}></SimpleBlock>
                        </Link>
                      </Col>
                    ) : (
                      <></>
                    );
                  })}
              </Row>
              <Row>
                <Col className="p-0">
                  <Link key={businessDevelopmentBoxes[2].id} to={`/${businessDevelopmentBoxes[2].link}`} className="simple-block-link">
                    <SimpleBlock
                      maxWidthText={"44%"}
                      headingText={businessDevelopmentBoxes[2].title}
                      backgroundColor={"black"}
                      textColor={"white"}
                      blockSize={2}></SimpleBlock>
                  </Link>
                </Col>
              </Row>
            </Container>
          </>
        </SectionContainer>

        <div id="reference-works"></div>
        <SectionContainer
          background={"white"}
          headingText={allStrapiBoxGroups[4].title}
          paragraph={<ReactMarkdown children={allStrapiBoxGroups[4].description.data.description} />}
          customClass={"no-bottom-margins"}>
          <>
            <Link to={`/${referenceBoxes[0].link}`} className="simple-block-link">
              <SimpleBlock maxWidthText={"44%"} headingText={referenceBoxes[0].title} backgroundColor={"black"} textColor={"white"} blockSize={2}></SimpleBlock>
            </Link>
          </>
        </SectionContainer>
        <Container fluid className="bg-white">
          <Row>
            {referenceBoxes &&
              referenceBoxes.map((item, index) => {
                const getBlocks = (index) => {
                  switch (index) {
                    case 1:
                      return (
                        <Link key={item.id} to={`/${item.link}`} className="simple-block-link">
                          <SimpleBlock maxWidthText={"50%"} headingText={item.title} backgroundColor={"black"} textColor={"white"} blockSize={1}></SimpleBlock>
                        </Link>
                      );
                    case 2:
                      return (
                        <Link key={item.id} to={`/${item.link}`} className="simple-block-link">
                          <SimpleBlock maxWidthText={"90%"} headingText={item.title} backgroundColor={"black"} textColor={"white"} blockSize={1}></SimpleBlock>
                        </Link>
                      );
                    case 3:
                      return (
                        <Link key={item.id} to={`/${item.link}`} className="simple-block-link">
                          <SimpleBlock maxWidthText={"70%"} headingText={item.title} backgroundColor={"black"} textColor={"white"} blockSize={1}></SimpleBlock>
                        </Link>
                      );
                    case 4:
                      return (
                        <Link key={item.id} to={`/${item.link}`} className="simple-block-link">
                          <SimpleBlock maxWidthText={"70%"} headingText={item.title} backgroundColor={"black"} textColor={"white"} blockSize={1}></SimpleBlock>
                        </Link>
                      );
                    default:
                      return <></>;
                  }
                };

                const blockToAdd = getBlocks(index);

                return index > 0 ? (
                  <Col key={item.id} sm={12} md={6} lg={3} className="p-0">
                    {blockToAdd}
                  </Col>
                ) : (
                  <></>
                );
              })}
          </Row>
        </Container>
        <PartnersSlider></PartnersSlider>

        <div id="contact"></div>
        <Contact></Contact>
      </main>
      <Footer />
    </>
  );
};

export default IndexPage;
